<template>
  <footer class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-flex is-align-items-flex-end totalSum">
          <div
            class="has-text-left mr-5 withoutVAT"
            v-if="
              this.selectedlist.value == 'summary' ||
              this.selectedlist.value == 'master' ||
              this.selectedlist.value == 'client'
            "
          >
            Total excl. VAT: {{ totalSum.withoutVAT }} RON
          </div>
          <div
            v-if="
              this.selectedlist.value == 'summary' ||
              this.selectedlist.value == 'master' ||
              this.selectedlist.value == 'client'
            "
            class="has-text-left ml-5 withVAT" 
          >
            Total incl. VAT: {{ totalSum.withVAT }} RON
          </div>
        </div>

        <div class="column eurAndTableFilter is-flex is-align-items-flex-end  is-align-items-center">
          
            <div class="field-label is-normal mr-3 pt-0 notPrint">
              <label class="label has-text-weight-normal" style="font-size: 14px">EUR </label>
            </div>
            <div class="field mb-0 mr-5 notPrint">
              <p class="control is-small">
                <input class="input is-small" type="number" placeholder="6.6" />
              </p>
            </div>

            <b-field type="is-link notPrint">
              <b-select v-model="selectedlist" size="is-small">
                <option
                  v-for="option in listType"
                  :value="option"
                  :key="option.value"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>
          </div>
       
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      listType: [
        {
          value: "master",
          label: "Master list",
        },
        {
          value: "client",
          label: "Client list",
        },
        {
          value: "paint",
          label: "Paint list",
        },
        {
          value: "acquisition",
          label: "Acquisition list",
        },
        {
          value: "production",
          label: "Production list",
        },
        {
          value: "summary",
          label: "Summary",
        },
      ],
    };
  },
  computed: {
    selectedlist: {
      get() {
        return this.$store.state.selectedListType;
      },
      set(newValue) {
        this.$store.dispatch("saveSelectedListType", newValue);
      },
    },
    totalSum: {
      get() {
        return this.$store.state.house.totalSum;
      },
    },
  },
};
</script>

<style scoped lang="scss">
footer{
    border-top: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to right, #f0f0f0, #505050) !important;
}

@media screen {
    .totalSum {
        font-size: 15px;
    }
}

@media print {
    footer {
        margin-top: 2rem
    }
    .totalSum {
        font-size: 12px;
        flex-direction: column;
        align-items: flex-start!important;

        .withVAT {
            margin-top: 1rem;
            margin-left: 0!important;
        }
    }
}
</style>