export const ADD_COMPONENT = 'ADD_COMPONENT';
export const ADD_COMPONENT_TO_HOUSE = 'ADD_COMPONENT_TO_HOUSE';
export const UPDATE_HOUSE_COMPONENTS = 'UPDATE_HOUSE_COMPONENTS';
export const INITIALISE_STORE = 'INITIALISE_STORE';
export const DELETE_SELECTED_ROW = 'DELETE_SELECTED_ROW';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_SELECTED_INSTANCE = 'UPDATE_SELECTED_INSTANCE';
export const SWITCH_INSTANCE = 'SWITCH_INSTANCE';
export const SHOW_LABEL_MODAL = 'SHOW_LABEL_MODAL';
export const CLOSE_LABEL_MODAL = 'CLOSE_LABEL_MODAL';
export const UPDATE_LABELS = 'UPDATE_LABELS';
export const SET_SELECTED_CELL = 'SET_SELECTED_CELL';
export const ADD_HOUSE_DATA = 'ADD_HOUSE_DATA';
export const UPDATE_TOTAL_SUM = 'UPDATE_TOTAL_SUM';
export const SAVE_SELECTED_LIST_TYPE = 'SAVE_SELECTED_LIST_TYPE';
