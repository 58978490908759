<template  >
  
  
   <span >{{ formula }}</span>

</template>

<script>
import store from "@/store/index.js";
export default {
  props: ["rowIndex", "prop", "model", "column", "data"],
  name: "tag",
  data() {
    return {};
  },
  mounted() {

  },
  computed: {
    columns: {
      get() {
        return store.state.columns.map((col) => {
          return col.prop;
        });
      },
    },
    formula: {
      get() {
        if (typeof this.model[this.prop] !== 'undefined') {

        let string = '1*3+l+h';
        console.log('this.model[this.prop]',this.model[this.prop]);

        // let string = this.model[this.prop];

        let requiredString = this.model[this.prop].substring(this.model[this.prop].indexOf("*") + 1, this.model[this.prop].indexOf("%"));

        console.log('requiredString', requiredString)

        let evalForm =  eval(this.model[this.prop].replaceAll("l",store.state.house.length).replaceAll("w",store.state.house.width).replaceAll("h",store.state.house.height).replaceAll("%", "*0.01"));

        // return eval(string.replaceAll("l",store.state.house.length).replaceAll("w",store.state.house.width).replaceAll("h",store.state.house.height));
        return evalForm;
        }
      }
    }
  },
};
</script>

<style scoped>
.tag-container {
  min-width: 150px;
}
</style>
