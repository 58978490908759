<template>
  <section class="hero pb-5">
    <div class="hero-head">
      <header class="navbar is-fixed-top pb-5">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item is-align-items-flex-end">
              <img src="@/assets/image/mainLogo.svg" alt="Logo" />
            </a>
          </div>

          <div
            id="navbarMenuHeroC"
            class="is-flex is-justify-content-space-between"
          >
            <div
              class="navbar-start is-flex is-flex-direction-column is-justify-content-flex-end ml-5"
            >
              <div
                class="house-name has-text-weight-medium has-text-left"
              >
                Tiny Lincoln Demo1
              </div>
              <div class="house-details notPrint">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field-label is-normal">
                      <label class="label has-text-weight-normal">L:</label>
                    </div>
                    <div class="field">
                      <div class="control is-small">
                        <input
                          v-model="houseDataLength"
                          class="input is-small"
                          type="number"
                          placeholder="6.6"
                          v-on:input="saveHouseData('length')"
                        />
                      </div>
                    </div>
                    <div class="field-label is-normal">
                      <label class="label has-text-weight-normal">W:</label>
                    </div>
                    <div class="field">
                      <p class="control is-small">
                        <input
                          v-model="houseDataWidth"
                          class="input is-small"
                          type="number"
                          placeholder="6.6"
                          v-on:input="saveHouseData('width')"
                        />
                      </p>
                    </div>

                    <div class="field-label is-normal">
                      <label class="label has-text-weight-normal">H:</label>
                    </div>
                    <div class="field">
                      <p class="control is-small">
                        <input
                          v-model="houseDataHeight"
                          class="input is-small"
                          type="number"
                          placeholder="6.6"
                          v-on:input="saveHouseData('height')"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="print is-flex houseData">
                <div class="mr-5">Length: {{ houseDataLength }} m</div>
                <div class="mr-5">Width: {{ houseDataWidth }} m</div>
                <div>Height: {{ houseDataHeight }} m</div>
              </div>
            </div>
            

            <div class="findItem navbar-end is-flex is-align-items-flex-end">
              <h2 class="print is-flex is-align-items-flex-end listTypePrint mr-5">
              {{ selectedlist.label }}
            </h2>
              <div class="field has-addons mb-0 mr-2 notPrint">
                <b-field>
                  <b-autocomplete
                    class="componentSearch has-text-left"
                    ref="addComponent"
                    v-model="name"
                    icon="magnify"
                    placeholder="Start to type"
                    :keep-first="keepFirst"
                    :open-on-focus="openOnFocus"
                    :data="filteredDataObj"
                    field="name"
                    @select="(option) => (selected = option)"
                    :clearable="clearable"
                    size="is-small"
                  >
                  </b-autocomplete>
                  <p class="control">
                    <b-button
                      type="is-info is-small"
                      label="Add"
                      @click="addComponentToHouse(selected)"
                      :disabled="!selected"
                    />
                  </p>
                </b-field>
              </div>

              <div class="field is-horizontal mr-2 mb-0 notPrint">
                <p class="control">
                  <button
                    @click="showModal('new', null)"
                    class="button buttonNewItem is-info is-small"
                  >
                    New Item
                  </button>
                </p>
                <p class="control">
                  <button
                    :disabled="selected == null"
                    @click="showModal('duplicate', selected)"
                    class="button buttonDuplicate is-info is-small"
                  >
                    Duplicate
                  </button>
                </p>
              </div>

              <button
                @click="showLabelModal('categories')"
                class="addNewCategory button is-info is-small notPrint mr-2"
              >
                Categories
              </button>
              <button
                @click="showLabelModal('tags')"
                class="addNewTag button is-info is-small notPrint"
              >
                Tags
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="designLine mt-5" style="width: 100%"></div> -->
      </header>
    </div>

    <!-- <p class="content"><b>Selected:</b> {{ selected }}</p> -->
    <add-new-element-modal
      v-on:close="closeModal('new')"
      v-if="modals.new"
      modal-type="new"
    ></add-new-element-modal>

    <add-new-element-modal
      v-on:close="closeModal('duplicate')"
      v-if="modals.duplicate"
      modal-type="duplicate"
    ></add-new-element-modal>

    <edit-labels-modal
      v-on:close="closeLabelModal('categories')"
      v-if="labelModals.categories"
      model-name="Categories"
      label-type="categories"
    ></edit-labels-modal>
    <edit-labels-modal
      v-on:close="closeLabelModal('tags')"
      v-if="labelModals.tags"
      model-name="Tags"
      label-type="tags"
    ></edit-labels-modal>
  </section>
</template>

<script>
import AddNewElementModal from "@/components/AddNewElementModal";
import EditLabelsModal from "@/components/EditLabelsModal";

export default {
  name: "Header",
  components: {
    AddNewElementModal,
    EditLabelsModal,
  },
  data() {
    return {
      keepFirst: false,
      openOnFocus: true,
      selected: null,
      name: "",
      clearable: true,
      editedHouseDataLength: null,
      editedHouseDataWidth: null,
      editedHouseDataHeight: null,
    };
  },
  computed: {
    filteredDataObj() {
      return this.$store.state.components.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
    modals: {
      get() {
        return this.$store.state.modals;
      },
    },
    labelModals: {
      get() {
        return this.$store.state.labelModals;
      },
    },
    houseDataLength: {
      get() {
        return this.$store.state.house.length;
      },
      set(newValue) {
        this.editedHouseDataLength = newValue;
      },
    },
    houseDataWidth: {
      get() {
        return this.$store.state.house.width;
      },
      set(newValue) {
        this.editedHouseDataWidth = newValue;
      },
    },
    houseDataHeight: {
      get() {
        return this.$store.state.house.height;
      },
      set(newValue) {
        this.editedHouseDataHeight = newValue;
      },
    },
    house: {
      get() {
        return this.$store.state.house;
      },
    },
    selectedlist: {
      get() {
        return this.$store.state.selectedListType;
      },
    },
  },
  methods: {
    showModal(modalType, model) {
      let payload = {
        type: modalType,
        selectedModel: model,
      };
      this.$store.dispatch("showModal", payload);
    },
    closeModal(type) {
      this.$store.dispatch("closeModal", type).then(() => {
        setTimeout(() => (this.name = ""), 10);
      });
    },
    addComponentToHouse(selected) {
      if (selected.unit_type != "none") {
        let globalMeasure = eval(
          selected.formula
            .replaceAll("l", this.house.length)
            .replaceAll("w", this.house.width)
            .replaceAll("h", this.house.height)
            .replaceAll("%", "*0.01")
        );
        selected.unit_data = globalMeasure;
      }
      selected.total_price = null;
      this.$store.dispatch("addComponentToHouse", selected).then(() => {
        setTimeout(() => (this.name = ""), 10);
        this.$emit("reload");
      });
    },
    showLabelModal(type) {
      this.$store.dispatch("showLabelModal", type);
    },
    closeLabelModal(type) {
      this.$store.dispatch("closeLabelModal", type);
    },
    saveHouseData(type) {
      let value;
      switch (type) {
        case "length":
          value = this.editedHouseDataLength;
          break;
        case "width":
          value = this.editedHouseDataWidth;
          break;
        default:
          value = this.editedHouseDataHeight;
          break;
      }
      let payload = { houseDataType: type, data: value };
      this.$store.dispatch("addHouseData", payload);

      let components = this.house.components.map((element) => {
        if (element.unit_type != "none") {
          return {
            ...element,
            unit_data: eval(
              element.formula
                .replaceAll("l", this.house.length)
                .replaceAll("w", this.house.width)
                .replaceAll("h", this.house.height)
                .replaceAll("%", "*0.01")
            ),
          };
        }
        return { ...element };
      });

      this.$store.dispatch("updateHouseComponents", components);
    },
  },
};
</script>

<style lang="scss" scoped >
#navbarMenuHeroC {
  width: 100%;
}
.navbar {
  .input {
    max-width: 4rem;
  }
}

.hero .navbar {
  background: white;
}

.navbar {
  border-bottom: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-image-source: linear-gradient(to right, #f0f0f0, #505050) !important;
}

.findItem {
  input {
    min-width: 16rem;
  }
}
.componentSearch {
  width: 22rem;
}

.buttonNewItem.button.is-small {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid white;
}

.buttonDuplicate.button.is-small {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}



@media screen {
  .print {
    display: none !important;
  }
  .house-name {
  font-size: 1.5rem;
}
}

@media print {
  .print {
    display: block;
  }
  .print.is-flex {
    display: flex !important;
  }

  .navbar {
    height: 4rem;
    .container {
      min-height: 4rem;

      .navbar-brand {
        margin-left: 2rem;
        a {
          height: 4rem;
        }
        .navbar-item {
          display: flex;
          img {
            max-height: 2rem;
          }
        }
      }

      #navbarMenuHeroC {
        height: 4rem;
        .navbar-start {
          .house-name {
            font-size: 16px;
            font-weight: bold;
          }
            .houseData {
            font-size: 12px;
          }

          
        }

        .navbar-end {
          .listTypePrint {
            font-size: 12px;
          }
        }
        
      }
    }
  }
}
</style>
