var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-flex is-align-items-flex-end totalSum"},[(
            this.selectedlist.value == 'summary' ||
            this.selectedlist.value == 'master' ||
            this.selectedlist.value == 'client'
          )?_c('div',{staticClass:"has-text-left mr-5 withoutVAT"},[_vm._v(" Total excl. VAT: "+_vm._s(_vm.totalSum.withoutVAT)+" RON ")]):_vm._e(),(
            this.selectedlist.value == 'summary' ||
            this.selectedlist.value == 'master' ||
            this.selectedlist.value == 'client'
          )?_c('div',{staticClass:"has-text-left ml-5 withVAT"},[_vm._v(" Total incl. VAT: "+_vm._s(_vm.totalSum.withVAT)+" RON ")]):_vm._e()]),_c('div',{staticClass:"column eurAndTableFilter is-flex is-align-items-flex-end  is-align-items-center"},[_vm._m(0),_vm._m(1),_c('b-field',{attrs:{"type":"is-link notPrint"}},[_c('b-select',{attrs:{"size":"is-small"},model:{value:(_vm.selectedlist),callback:function ($$v) {_vm.selectedlist=$$v},expression:"selectedlist"}},_vm._l((_vm.listType),function(option){return _c('option',{key:option.value,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field-label is-normal mr-3 pt-0 notPrint"},[_c('label',{staticClass:"label has-text-weight-normal",staticStyle:{"font-size":"14px"}},[_vm._v("EUR ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field mb-0 mr-5 notPrint"},[_c('p',{staticClass:"control is-small"},[_c('input',{staticClass:"input is-small",attrs:{"type":"number","placeholder":"6.6"}})])])}]

export { render, staticRenderFns }