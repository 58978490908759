import Vue from 'vue'
import Vuex from 'vuex'
import {
  ADD_COMPONENT,
  ADD_COMPONENT_TO_HOUSE,
  UPDATE_HOUSE_COMPONENTS,
  INITIALISE_STORE,
  DELETE_SELECTED_ROW,
  SET_COLUMNS,
  SHOW_MODAL,
  CLOSE_MODAL,
  UPDATE_SELECTED_INSTANCE,
  UPDATE_LABELS,
  SHOW_LABEL_MODAL,
  CLOSE_LABEL_MODAL,
  SWITCH_INSTANCE,
  ADD_HOUSE_DATA,
  UPDATE_TOTAL_SUM,
  SAVE_SELECTED_LIST_TYPE
} from './mutation-types'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedListType: {
      value: "master",
      label: "Master list",
    },
    selectedModelInstance: {},
    modals: {
      new: false,
      duplicate: false,
      edit: false
    },
    columns: [],
    house: {
      totalSum: {
        withVAT: 0,
        withoutVAT: 0,
      },
      width: 6,
      length: 5,
      height: 10,
      components: [
        {
          "id": 1,
          "name": "7.80 Example - 2 axe",
          "description": "Trailer 7.80 Example - 2 axe",
          "link": "https://Example.nl/123287392873",
          "image": "",
          "unit_price": 15916,
          "unit_type": "none",
          "formula": null,
          "unit_data": null,
          "quantity": 1,
          "effort": 200,
          "category": "trailer",
          "tags": ["futo"],
          "ral_code": "",
          "phases": [],
          "client": true,
          "paint": false,
          "acquisition": true,
          "production": false,
          "total_price": null
        }
      ]
    },
    components: [
      {
        "id": 1,
        "name": "7.20 Example - 2 axe",
        "description": "Trailer 7.20 Example - 2 axe",
        "link": "https://Example.nl/123287392873",
        "image": "Example.jpg",
        "unit_price": 15441,
        "unit_type": "none",
        "formula": null,
        "unit_data": null,
        "quantity": 1,
        "effort": 201,
        "category": "trailer",
        "tags": ["futo"],
        "ral_code": "",
        "phases": [],
        "client": true,
        "paint": false,
        "acquisition": true,
        "production": false
      },
      {
        "id": 2,
        "name": "7.80 Example - 2 axe",
        "description": "Trailer 7.80 Example - 2 axe",
        "link": "https://Example.nl/123287392873",
        "image": "",
        "unit_price": 15916,
        "unit_type": "none",
        "formula": null,
        "unit_data": null,
        "quantity": 1,
        "effort": 0,
        "category": "trailer",
        "tags": ["futo"],
        "ral_code": "",
        "phases": [],
        "client": true,
        "paint": false,
        "acquisition": true,
        "production": false
      },
      {
        "id": 3,
        "name": "8.40 Example - 3 axe",
        "description": "Trailer 8.40 Example - 3 axe",
        "link": "https://Example.nl/123287392873",
        "image": "",
        "unit_price": "18054",
        "unit_type": "none",
        "formula": null,
        "unit_data": null,
        "quantity": 1,
        "effort": 300,
        "category": "trailer",
        "tags": ["futo"],
        "ral_code": "",
        "phases": [],
        "client": true,
        "paint": false,
        "acquisition": true,
        "production": false
      },
      {
        "id": 4,
        "name": "usa_intrare_lemn",
        "description": "Usa intrare lemn",
        "link": "http://ecotiny.house/usa_model3",
        "image": "",
        "unit_price": 50,
        "unit_type": "m2",
        "formula": "1.8",
        "unit_data": null,
        "quantity": 1,
        "effort": 100,
        "category": "door",
        "tags": ["futo"],
        "ral_code": "RAL9010",
        "phases": ["asztalos", "furdo"],
        "client": true,
        "paint": true,
        "acquisition": true,
        "production": true
      },
      {
        "id": 5,
        "name": "usa_interioara_culisanta_din_lambriu",
        "description": "Usa interioara culisanta din lambriu",
        "link": "http://ecotiny.house/usa_model3",
        "image": "",
        "unit_price": 680,
        "unit_type": "m2",
        "formula": "1.62",
        "unit_data": null,
        "quantity": 1,
        "effort": 100,
        "category": "door",
        "tags": ["futo"],
        "ral_code": "RAL9010",
        "phases": ["asztalos", "furdo"],
        "client": true,
        "paint": true,
        "acquisition": true,
        "production": true
      },
      {
        "id": 6,
        "name": "tabla alu",
        "description": "Tabla aluminiu 1 mm + placaj Tego 15+coltar aluminiu",
        "link": "https://example.ro/tabla_tip_5mm",
        "image": "",
        "unit_price": 680,
        "unit_type": "m2",
        "formula": '2*((l*3.5)+(w*3.5))*110%',
        "unit_data": null,
        "quantity": 1,
        "effort": 100,
        "category": "cover",
        "tags": ["struktura", "alvazvedo", "calc_alapterulet"],
        "ral_code": "RAL9010",
        "phases": ["szerkezet"],
        "client": false,
        "paint": false,
        "acquisition": true,
        "production": true
      },
      {
        "id": 7,
        "name": "acoperis tabla intr-o apa",
        "description": "Acoperis tabla intr-o apa",
        "link": "https://example.ro/tabla_tip_5mm",
        "image": "",
        "unit_price": 130.25,
        "unit_type": "m2",
        "formula": '2*((l*3.5)+(w*3.5))*110%',
        "unit_data": null,
        "quantity": 1,
        "effort": 100,
        "category": "cover",
        "tags": ["struktura", "alvazvedo", "calc_alapterulet"],
        "ral_code": "",
        "phases": ["szerkezet"],
        "client": false,
        "paint": false,
        "acquisition": true,
        "production": true
      },
      {
        "id": 8,
        "name": "Ferestre PVC - Barrier",
        "description": "Ferestre PVC - Barrier",
        "link": "https://example.ro/barrier_pvc_156",
        "image": "",
        "unit_price": 680,
        "unit_type": "m2",
        "formula": "7",
        "unit_data": null,
        "quantity": 1,
        "effort": 0,
        "category": "window",
        "tags": ["struktura", "alvazvedo", "calc_alapterulet"],
        "ral_code": "RAL9010",
        "phases": ["szerkezet", "asztalos"],
        "client": false,
        "paint": true,
        "acquisition": true,
        "production": true
      },
      {
        "id": 9,
        "name": "Ferestre lemn",
        "description": "Ferestre lemn",
        "link": "https://example.ro/barrier_pvc_156",
        "image": "",
        "unit_price": 1900,
        "unit_type": "m2",
        "formula": "5",
        "unit_data": null,
        "quantity": 1,
        "effort": 0,
        "category": "window",
        "tags": ["struktura", "alvazvedo", "calc_alapterulet"],
        "ral_code": "RAL9010",
        "phases": ["szerkezet", "asztalos"],
        "client": false,
        "paint": true,
        "acquisition": true,
        "production": true
      }
    ],
    categories: ["trailer", "door", "structure", "cover", "window"],
    tags: ["futo", "calc1", "struktura", "alvazvedo", "calc_alapterulet", "calc2"],
    phases: ["asztalos", "furdo", "villanyszereles"],
    labelModals: {
      categories: false,
      tags: false,
      phases: false
    }
  },
  mutations: {
    [ADD_COMPONENT](state, payload) {
      state.components.push(payload);
    },
    [ADD_COMPONENT_TO_HOUSE](state, payload) {
      let max = Math.max.apply(Math, state.house.components.map((comp, index) => index)) + 2
      state.house.components.push({ ...payload, id: max });
    },
    [UPDATE_HOUSE_COMPONENTS](state, payload) {
      Vue.set(state.house,'components',payload);
    },
    [INITIALISE_STORE](state) {
      if (localStorage.getItem('store')) {
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      }
    },
    [DELETE_SELECTED_ROW](state, payload) {
      let index = state.house.components.findIndex((comp) => {
        return comp.id == payload.id
      });
      state.house.components.splice(index, 1);      
    },
    [SET_COLUMNS](state, payload) {
      Vue.set(state, 'columns', payload);
    },
    [SHOW_MODAL](state, payload) {
      Vue.set(state.modals, payload.type, true);
      Vue.set(state, 'selectedModelInstance', payload.selectedModel);
    },
    [CLOSE_MODAL](state, payload) {
      Vue.set(state.modals, payload, false);
    },
    [UPDATE_SELECTED_INSTANCE](state, payload) {
      let index = state.house.components.findIndex((comp) => {
        return comp.id == payload.id
      });
      if(state.house.components[index].formula != payload.formula) {
        let globalMeasure = eval(payload.formula.replaceAll("l",state.house.length).replaceAll("w",state.house.width).replaceAll("h",state.house.height).replaceAll("%", "*0.01"));
        payload.unit_data = globalMeasure;
      }
      Vue.set(state.house.components, index, payload);
    },
    [SHOW_LABEL_MODAL](state, payload) {
      Vue.set(state.labelModals, payload, true);
    },
    [CLOSE_LABEL_MODAL](state, payload) {
      Vue.set(state.labelModals, payload, false);
    },
    [UPDATE_LABELS](state, payload) {
      Vue.set(state, payload.type, payload.data);
    },
    [SWITCH_INSTANCE](state, payload) {
      let index = state.house.components.findIndex((comp) => {
        return comp.id == payload.id
      });

      Vue.set(state.house.components, index, {
        ...payload.component,
        id: payload.id
      });
    },
    [ADD_HOUSE_DATA](state, payload) {
      Vue.set(state.house, payload.houseDataType, Number(payload.data))
    },
    [UPDATE_TOTAL_SUM] (state, payload) {
      Vue.set(state.house, 'totalSum', payload)
    },
    [SAVE_SELECTED_LIST_TYPE] (state, payload) {
      Vue.set(state, 'selectedListType', payload )
    }
  },
  actions: {
    addComponent(context, payload) {
      context.commit('ADD_COMPONENT', payload);
    },
    addComponentToHouse(context, payload) {
      context.commit('ADD_COMPONENT_TO_HOUSE', payload)
    },
    updateHouseComponents(context, payload) {
      context.commit('UPDATE_HOUSE_COMPONENTS', payload)
    },
    deleteSelectedRow(context, payload) {
      context.commit('DELETE_SELECTED_ROW', payload)
    },
    setColumns(context, payload) {
      context.commit('SET_COLUMNS', payload)
    },
    showModal(context, payload) {
      context.commit('SHOW_MODAL', payload)
    },
    closeModal(context, payload) {
      context.commit('CLOSE_MODAL', payload)
    },
    updateSelectedInstance(context, payload) {
      context.commit('UPDATE_SELECTED_INSTANCE', payload)
    },
    showLabelModal(context, payload) {
      context.commit('SHOW_LABEL_MODAL', payload)
    },
    closeLabelModal(context, payload) {
      context.commit('CLOSE_LABEL_MODAL', payload)
    },
    updateLabels(context, payload) {
      context.commit('UPDATE_LABELS', payload)
    },
    switchInstance(context, payload) {
      context.commit('SWITCH_INSTANCE', payload);
    },
    addHouseData(context, payload) {
      context.commit('ADD_HOUSE_DATA', payload);
    },
    updateTotalSum(context, payload) {
      context.commit('UPDATE_TOTAL_SUM', payload);
    },
    saveSelectedListType(context, payload) {
      context.commit('SAVE_SELECTED_LIST_TYPE', payload);
    }
  },
  modules: {
  },

})
