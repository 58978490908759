<template>
  
    <div v-if="columns.includes(prop)" class="has-text-centered">
      <span class="tickbox"></span>
     
    </div>
</template>

<script>
import store from "@/store/index.js";


export default {
  props: ["rowIndex", "prop", "model"],
  name: "checkbox",
  data() {
    return {
      count: 0,
    };
  },
  mounted() {},
  methods: {
       
  },
  computed: {
    columns: {
      get() {
        return store.state.columns.map((col) => {
          return col.prop;
        });
      },
    },
  },
};
</script>

<style >
.tickbox:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border: 2px solid black;
}
</style>