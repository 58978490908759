<template>
  <div class="table-container">
    <table class="table is-fullwidth is-scrollable">
      <thead>
        <tr>
          <th :key="index" v-for="(col, index) in columns">
            <div class="cell">
              <span>{{ col.name }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr :key="rowIndex" v-for="(row, rowIndex) in rows">
          <td :key="index" v-for="(col, index) in columns">
            <div class="cell">
              <span
                v-if="
                  ![
                    'tag',
                    'category',
                    'phases',
                    'total_price',
                    'link',
                    'image',
                  ].includes(col.prop)
                "
              >
                {{ row[col.prop] }}
              </span>
              <span v-else-if="['tag', 'phases'].includes(col.prop)">
                <tag :prop="col.prop" :model="row"></tag>
              </span>
              <span v-else-if="['category'].includes(col.prop)">
                <category :prop="col.prop" :model="row"></category>
              </span>
              <span v-else-if="['total_price'].includes(col.prop)">
                {{ getTotalSum(row) }}
              </span>
              <a
                :href="row[col.prop]"
                v-else-if="['link', 'image'].includes(col.prop)"
              >
                {{ row[col.prop] }}
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Category from "./Cells/Category.vue";
import Tag from "./Cells/Tag.vue";
export default {
  components: { Tag, Category },
  props: ["source"],
  data() {
    return {};
  },
  methods: {
    getTotalSum(row) {
      let quantity = row["quantity"] || 0;
      let measure = row["unit_data"] || 1;
      let unit_price = row["unit_price"] || 0;
      let effort = row["effort"] || 0;
      let unitType = row["unit_type"];
      let totalPrice;

      if (row["total_price"]) {
        return `${Number.isInteger(row["total_price"]) ? row["total_price"] : row["total_price"].toFixed(2)} ron`;
      } else {
        if (unitType == "none") {
          totalPrice = Number(quantity) * Number(unit_price) + Number(effort);
        } else {
          totalPrice =
            Number(quantity) * Number(measure) * Number(unit_price) +
            Number(effort);
        }

        return `${
          Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2)
        } ron`;
      }
    },
  },
  computed: {
    columns() {
      let columns = this.$store.state.columns;
      let commentsIndex = columns.findIndex((col) => col.prop == "comments");

      if (commentsIndex > -1) {
        let modifiedColumns = columns.slice();
        modifiedColumns.push(modifiedColumns.splice(commentsIndex, 1)[0]);
        return modifiedColumns;
      }

      return columns;
    },
    rows: {
      get() {
        var result = [];

        if (this.selectedlist.value == "summary") {
          this.source.reduce(function (res, value) {
            if (!res[value.category]) {
              res[value.category] = {
                category: value.category,
                total_price: 0,
              };
              result.push(res[value.category]);
            }
            if (value.total_price) {
              res[value.category].total_price += Number(value.total_price);
            } else {
              let measure = value.unit_data || 1;
              res[value.category].total_price +=
                Number(value.quantity) *
                  Number(measure) *
                  Number(value.unit_price) +
                Number(value.effort);
            }
            return res;
          }, {});
        } else {
          result = this.source;
        }

        return result;
      },
    },
    selectedlist: {
      get() {
        return this.$store.state.selectedListType;
      },
    },
  },
};
</script>

<style scoped>
.table-container {
  font-size: 12px;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}
.cell {
  width: 100%;
  min-height: 42px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.table-container {
  height: 75vh;
  width: 80vw;
  margin: 0 auto;
  overflow-y: scroll;
}

@media (max-width: 1700px) {
  .table-container {
    height: 65vh;
    width: 95vw;
  }
}

@media print {
  .table-container {
    height: auto;
    overflow-y: auto;
  }
}
</style>
