<template>
  <div class="grid-container">
    <v-grid
      :key="gridKey1"
      v-show="selectedlist.value == 'master'"
      theme="material"
      :source="filteredHouseComponents"
      :columns="getFilteredColumns"
      :columnTypes="columnTypes"
      :readonly="fieldIsReadOnly"
      :stretch="false"
    />

    <print-view
      v-show="selectedlist.value != 'master'"
      :source="filteredHouseComponents"
    >
    </print-view>

    <!-- <v-grid
      theme="material"
      :key="gridKey2"
      :source="groupedHouseComponents"
      :columns="sumByCategoryColumns"
      :columnTypes="columnTypes"
      :readonly="fieldIsReadOnly"
      :stretch="false"
      v-show="selectedlist.value == 'summary'"
    /> -->

    <add-new-element-modal
      v-on:close="closeModal('edit')"
      v-if="modals.edit"
      project-name="Tiny Lincoln Demo1"
      modal-type="edit"
    ></add-new-element-modal>
    <!-- :selected-component="this.selectedModel" -->
    
  </div>
</template>

<script>
import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import NumberColumnType from "@revolist/revogrid-column-numeral";
import Tag from "./Cells/Tag";
import Category from "./Cells/Category";
import Tools from "./Cells/Tools";
import Dropdown from "./Cells/Dropdown";
import Formula from "./Cells/Formula";
import Checkbox from "./Cells/Checkbox";
import AddNewElementModal from "./../AddNewElementModal";
import PrintView from "./PrintView.vue";

export default {
  name: "mainDataGrid",
  components: {
    VGrid,
    AddNewElementModal,
    PrintView,
  },
  data() {
    return {
      gridKey1: 0,
      gridKey2: 100,
      totalSum: {
        withVAT: 0,
        withoutVAT: 0,
      },
      columnTypes: {
        number: new NumberColumnType(),
      },
      listType: {
        master: [
          "id",
          "category",
          "tags",
          "name",
          "unit_price",
          "quantity",
          "unit_data",
          "effort",
          "total_price",
          "ral_code",
          "comments",
          "tools",
        ],
        client: [
          "description",
          "link",
          "image",
          "measure",
          "quantity",
          "total_price",
        ],
        paint: ["name", "measure", "quantity", "ral_code", "comments"],
        production: [
          "name",
          "measure",
          "quantity",
          "phases",
          "comments",
          "checkbox",
        ],
        acquisition: [
          "name",
          "link",
          "unit_price",
          "measure",
          "quantity",
          "comments",
        ],
        summary: ["category", "total_price"],
      },
      columns: [
        {
          prop: "category",
          name: "Category",
          sortable: true,
          order: "asc",
          readonly: true,
          cellTemplate: VGridVueTemplate(Category),
          size: 100,
        },
        {
          prop: "tags",
          name: "Tags",
          sortable: true,
          order: "asc",
          readonly: true,
          cellTemplate: VGridVueTemplate(Tag),
          size: 160,
        },
        {
          prop: "name",
          name: "Name",
          sortable: true,
          order: "asc",
          size: 210,
          readonly: true,
          cellTemplate: VGridVueTemplate(Dropdown),
          // apply this for custom properties
          cellProperties: ({ prop, model, data, column }) => {
            return {
              style: {
                color: "black",
              },
              class: {
                noPadding: true,
              },
            };
          },
        },
        {
          prop: "description",
          name: "Description",
          readonly: true,
          size: 210,
        },
        {
          prop: "unit_price",
          name: "Unit price",
          readonly: true,
          columnType: "number",
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {},
              props.model[props.prop] ? props.model[props.prop] + " ron" : ""
            );
          },
          size: 100,
        },
        {
          prop: "effort",
          name: "Effort",
          readonly: true,
          columnType: "number",
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {},
              props.model[props.prop] ? props.model[props.prop] + " ron" : ""
            );
          },
          size: 80,
        },
        {
          prop: "quantity",
          name: "Piece",
          columnType: "number",
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {},
              props.model[props.prop] ? props.model[props.prop] + " buc" : ""
            );
          },
          size: 80,
        },
        {
          name: "Measure",
          prop: "unit_data",
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {},
              props.model[props.prop] ?  Number.isInteger(props.model[props.prop]) ? props.model[props.prop]  : props.model[props.prop].toFixed(2) + " " + props.model["unit_type"] : ""
            );
          },
          // apply this for custom properties
          cellProperties: ({ prop, model, data, column }) => {
            return {
              style: {
                color: "black",
              },
              class: {
                disabled: true,
              },
            };
          },
          size: 90,
        },

        {
          prop: "total_price",
          name: "Total price",
          columnType: "number",
          cellTemplate: (createElement, props) => {
            return createElement(
              "span",
              {
                style: {
                  color: this.getTotalSum(props).edited ? "red" : "black",
                },
              },
              this.getTotalSum(props).value
            );
          },
          size: 110,
        },
        {
          prop: "formula",
          name: "Formula",
          sortable: true,
          order: "asc",
          readonly: true,
          cellTemplate: VGridVueTemplate(Formula),
          size: 160,
        },
        { prop: "ral_code", name: "RAL Code", size: 95 },
        {
          prop: "phases",
          name: "Phases",
          cellTemplate: VGridVueTemplate(Tag),
          size: 200,
        },
        {
          prop: "comments",
          name: "Comments",
          size: 285,
        },

        { prop: "link", name: "Link", size: 250 },
        { prop: "image", name: "Image", size: 150 },
        {
          prop: "tools",
          name: "Tools",
          readonly: true,
          cellTemplate: VGridVueTemplate(Tools),
          size: 82,
          // pin: 'colPinEnd'
        },
        {
          prop: "checkbox",
          name: "Done",
          cellTemplate: VGridVueTemplate(Checkbox),
        },
      ],
    };
  },
  watch: {
    selectedlist: {
      handler() {
        this.gridKey1++;
        this.gridKey2++;
      },
    },
    getFilteredColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.setColumns(newValue);
      },
    },
    houseComponents: {
      deep: true,
      immediate: true,
      handler() {
        let total = 0;
        this.houseComponents.forEach((element) => {
          if (element.total_price) {
            total += Number(element.total_price);
          } else {
            let quantity = element.quantity || 1;
            let measure = element.unit_data || 1;
            let unit_price = element.unit_price || 0;
            let effort = element.effort || 0;
            let unitType = element.unit_type;

            if (unitType == "none") {
              total += Number(quantity) * Number(unit_price) + Number(effort);
            } else {
              total +=
                Number(quantity) * Number(measure) * Number(unit_price) +
                Number(effort);
            }
          }
        });

        this.totalSum = {
          withoutVAT: new Intl.NumberFormat().format(total),
          withVAT: new Intl.NumberFormat().format(Number(total) * 1.19),
        };

        this.$store.dispatch("updateTotalSum", this.totalSum);
      },
    },
  },
  computed: {
    houseComponents: {
      get() {
        return this.$store.state.house.components;
      },
    },
    filteredHouseComponents: {
      get() {
        let filteredRows = this.houseComponents.filter((row) => {
          switch (this.selectedlist.value) {
            case "paint":
              return row.paint == true;
            case "acquisition":
              return row.acquisition == true;
            case "production":
              return row.production == true;
            case "client":
              return row.client == true;
            default:
              return row;
          }
        });
        return filteredRows;
      },
    },
    getFilteredColumns: {
      get() {
        return this.columns.filter((column) => {
          return this.listType[this.selectedlist.value].includes(column.prop);
        });
      },
    },

    fieldIsReadOnly: {
      get() {
        if (this.selectedlist.value == "master") {
          return false;
        } else {
          return true;
        }
      },
    },
    modals: {
      get() {
        return this.$store.state.modals;
      },
    },
    selectedModel: {
      get() {
        return this.$store.selectedModelInstance;
      },
    },
    selectedlist: {
      get() {
        return this.$store.state.selectedListType;
      },
    },
  },
  methods: {
    getTotalSum(props) {
      let quantity = props.model["quantity"] || 0;
      let measure = props.model["unit_data"] || 1;
      let unit_price = props.model["unit_price"] || 0;
      let effort = props.model["effort"] || 0;
      let unitType = props.model["unit_type"];
      let totalPrice;

      if (props.model[props.prop]) {
        return {
          edited: true,
          value: `${props.model[props.prop]} ron`,
        };
      } else {
        let component = props.model;

        if (unitType == "none") {
          totalPrice = Number(quantity) * Number(unit_price) + Number(effort);
        } else {
          totalPrice =
            Number(quantity) * Number(measure) * Number(unit_price) +
            Number(effort);
        }

        return {
          edited: false,
          value: `${Number.isInteger(totalPrice) ? totalPrice  : totalPrice.toFixed(2)} ron`,
        };
      }
    },
    setColumns(newValue) {
      this.$store.dispatch("setColumns", newValue);
    },
    closeModal(type) {
      let that = this;
      this.$store.dispatch("closeModal", type).then(() => {
        setTimeout(() => (this.name = ""), 10);
      });
    },
    updateStateRows() {
      this.$store.dispatch("updateHouseComponents", this.houseComponents);
    },
  },
};
</script>

<style scoped>
.grid-container {
  height: calc(100vh - 94px - 125px);
  width: 80vw;
  margin: 0 auto;
}

@media (max-width: 1700px) {
  .grid-container {
    height: calc(100vh - 94px - 125px);
    width: 95vw;
  }
}

@media print {
  .grid-container {
    height: auto;
    overflow-y: auto;
  }
}
.invisible {
  display: none;
}
</style>
