<template>
  
    <div v-if="columns.includes(prop)" class="has-text-centered">
      <span @click="deleteComponent" class="fas fa-trash-alt mr-2"></span>
      <span @click="duplicateComponent" class="far fa-copy mr-2"></span>
      <span @click="showModal('edit', model)" class="far fa-edit"></span>
     
    </div>
</template>

<script>
import store from "@/store/index.js";


export default {
  props: ["rowIndex", "prop", "model"],
  name: "tools",
  data() {
    return {
      count: 0,
    };
  },
  mounted() {},
  methods: {
    iAmClicked(e) {
      this.count++;
    },
    deleteComponent() {
      store.dispatch("deleteSelectedRow", this.model);
    },
    duplicateComponent() {
      this.model.total_price = null
      store.dispatch("addComponentToHouse", this.model);
    },
    showModal(modalType, model) {
      let payload = {
        type: modalType,
        selectedModel: model
      }
      store.dispatch("showModal", payload);
    },
    
  },
  computed: {
    columns: {
      get() {
        return store.state.columns.map((col) => {
          return col.prop;
        });
      },
    },
  },
};
</script>

<style scoped>
span:hover {
  cursor: pointer;
}
</style>