<template>
  <div class="home">
    <Header/>
    <div class="container">
      <main-data-grid> </main-data-grid>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import TableRow from "../components/MainTable/TableRow.vue";
import MainDataGrid from "../components/MainTable/MainDataGrid.vue";

export default {
  name: "Home",
  data() {
    return {
      
    };
  },
  components: {
    Header,
    TableRow,
    MainDataGrid,
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
}

.select.is-link select {
  background-color: #3298dc;
  color: white;
  font-size: 0.75rem;
  border-radius: 2px;
}

.tagsColumn {
  max-width: 200px;
}
@media screen {
  .print {
    display: none !important;
  }
}

@media print {
  /* visible when printed */
  .notPrint {
    display: none !important;
  }
}

@media screen and (min-width: 1408px) {

  .container:not(.is-max-desktop):not(.is-max-widescreen) {
      max-width: 1408px;
  }
}

@media screen and (min-width: 1216px) {

  .container:not(.is-max-desktop) {
      max-width: 1216px;
  }
}
revo-grid[theme=material] revogr-data .rgCell {
    padding: 0 8px;
}

revo-grid {
 font-size: .75rem;
}

revo-grid[theme=material] revogr-data .rgRow.focused-rgRow {
    background-color: white;
}

.noPadding {
  padding: 0;
}

</style>
