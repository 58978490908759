var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table is-fullwidth is-scrollable"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(col,index){return _c('th',{key:index},[_c('div',{staticClass:"cell"},[_c('span',[_vm._v(_vm._s(col.name))])])])}),0)]),_c('tbody',_vm._l((_vm.rows),function(row,rowIndex){return _c('tr',{key:rowIndex},_vm._l((_vm.columns),function(col,index){return _c('td',{key:index},[_c('div',{staticClass:"cell"},[(
                ![
                  'tag',
                  'category',
                  'phases',
                  'total_price',
                  'link',
                  'image' ].includes(col.prop)
              )?_c('span',[_vm._v(" "+_vm._s(row[col.prop])+" ")]):(['tag', 'phases'].includes(col.prop))?_c('span',[_c('tag',{attrs:{"prop":col.prop,"model":row}})],1):(['category'].includes(col.prop))?_c('span',[_c('category',{attrs:{"prop":col.prop,"model":row}})],1):(['total_price'].includes(col.prop))?_c('span',[_vm._v(" "+_vm._s(_vm.getTotalSum(row))+" ")]):(['link', 'image'].includes(col.prop))?_c('a',{attrs:{"href":row[col.prop]}},[_vm._v(" "+_vm._s(row[col.prop])+" ")]):_vm._e()])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }