<template>
  <div>
    <b-field type="is-transparent" v-if="toggle">
      <b-select
        v-model="selected"
        v-on:change.native="switchRow"
        v-on:enter.native="saveChange"
        :expanded="true"
        placeholder="Switch to alternative"
      >
        <option :key="comp.id" :value="comp" v-for="comp in components">
          {{ comp.name }}
        </option>
      </b-select>
    </b-field>
    <div class="has-text-left">
      <span class="switch fas fa-random " @click="switchToAlternative"></span>
      <span class="ml-2" v-if="!toggle">{{ model[prop] }}</span>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  props: ["rowIndex", "model", "prop"],
  data() {
    return {
      selected: null,
      toggle: false,
    };
  },
  mounted() {},
  computed: {
    components: {
      get() {
        return store.state.components.filter((comp) => {
          return comp.tags.some((tag) => this.model.tags.includes(tag));
        });
      },
    },
    houseComponents: {
      get() {
        return store.state.house.components;
      },
    },
  },
  methods: {
    switchRow(e) {
      let payload = {
        id: this.model.id,
        component: this.selected,
      };
      store.dispatch("switchInstance", payload).then(() => {
        this.toggle = false;
        this.selected = null;
      });
    },
    switchToAlternative() {
      this.toggle = true;
    },
    saveChange() {
      this.toggle = false;
    },
  },
};
</script>

<style scoped lang="scss">
.switch {
  cursor: pointer;
}
</style>
