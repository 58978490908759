import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Vuelidate);
Vue.use(Buefy);
require('@/assets/scss/main.scss');

Vue.config.productionTip = false


// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});



new Vue({
  router,
  store,
  beforeCreate() {
		this.$store.commit('INITIALISE_STORE');
	},
  render: h => h(App)
}).$mount('#app')
