<template  >
  <div v-if="columns.includes(prop)" class="tag-container">
    <div class="columns is-multiline is-gapless">
      <div class="column is-narrow" :key="prop" v-for="prop in model[prop]">
        <span class="tag is-info mr-2">{{ prop }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  props: ["rowIndex", "prop", "model", "column", "data"],
  name: "tag",
  data() {
    return {};
  },
  mounted() {

  },
  computed: {
    columns: {
      get() {
        return store.state.columns.map((col) => {
          return col.prop;
        });
      },
    },
  },
};
</script>

<style scoped>
.tag-container {
  min-width: 150px;
}
</style>
