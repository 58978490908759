<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ modelName }}
        </p>
        <button
          @click="close"
          class="delete"
          aria-label="close"
          data-bulma-modal="close"
        ></button>
      </header>
      <section
        class="modal-card-body is-flex is-flex-direction-column is-justify-content-space-between"
      >
        <b-field>
          <b-taginput
            v-model="selectedLabelTypeByCategory"
            :allow-new="true"
            :open-on-focus="false"
            field="user.first_name"
            icon="label"
            placeholder="Add a tag"
          >
          </b-taginput>
        </b-field>

      </section>
      <footer class="modal-card-foot">
        <button @click="save" class="button is-info is-small">
          Save changes
        </button>
        <button @click="close" class="button is-small" data-bulma-modal="close">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "editLabels",
  data() {
    return {
      selectedLabels: null,
      editedLabels: null
    };
  },
  props: ["modelName", "labelType"],
  computed: {
    selectedLabelTypeByCategory: {
      get() {
        return this.$store.state[this.labelType];
      },
      set(newValue) {
        this.editedLabels = newValue
      }
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      let payload = {
        type: this.labelType,
        data: this.editedLabels,
      };
      this.$store.dispatch("updateLabels", payload).then((response) => {
        this.close();
      });
    },
  },
};
</script>