<template>
<tr>
    <td class="is-vcentered"><span class="tag is-primary">{{component.category}}</span></td>
    <td class="is-vcentered"><span class="tag is-link mr-2" v-for="tag in component.tags">{{tag}}</span></td>
    <td class="is-vcentered">{{component.name}}</td>
    <td class="is-vcentered">{{component.unit_price}} $</td>
    <td class="is-vcentered">
        <b-input placeholder="Quantity" size="is-small" v-model="component.quantity"></b-input>
    </td>
    <td class="is-vcentered">
        <b-input placeholder="Measure" size="is-small" v-model="component.measure"></b-input>
    </td>
    <td class="is-vcentered">
        <b-input placeholder="Effort" size="is-small" v-model="component.effort"></b-input>
    </td>
    <td class="is-vcentered">{{component.unit_price * component.quantity}} $
    </td>
    <td class="is-vcentered"></td>
</tr>
</template>

<script>
export default {
    props: ['component'],
    data() {
        return {

        }
    }
}
</script>
