<template>
  <div v-if="columns.includes(prop)" class="columns category-container">
    <div class="column">
      <span class="tag is-danger mr-2">{{ model[prop] }}</span>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  props: ["rowIndex", "prop", "model"],
  name: "category",
  data() {
    return {};
  },
  computed: {
    columns: {
      get() {
        return store.state.columns.map((col) => {
          return col.prop;
        });
      },
    },
  },
};
</script>

<style scoped>
.category-container {
  min-width: 100px;
  width: 100px;
}
</style>
