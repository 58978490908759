<template>

  <div class="modal is-active">
    
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head is-align-items-flex-start">
        <div class="modal-card-title">
          <h3 v-if="projectName" class="size-3 mb-4 has-text-weight-bold">
            {{ projectName }}
          </h3>
          <p class="modal-card-title">
            {{ component.name || "New component" }}
          </p>
        </div>
        <!-- <div>{{!$v.$invalid ? 'valid': 'invalid'}}</div> -->
        <button
          @click="close"
          class="delete"
          aria-label="close"
          data-bulma-modal="close"
        ></button>
      </header>
      <section
        class="modal-card-body is-flex is-flex-direction-column is-justify-content-space-between"
      >
        <div class="checkboxGroup is-flex is-justify-content-space-between">
          <b-field>
            <b-checkbox v-model="component.client" style="font-size: 14px"> Client </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="component.paint" type="is-info" style="font-size: 14px">
              Paint
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="component.acquisition" type="is-warning" style="font-size: 14px">
              Acquisition
            </b-checkbox>
          </b-field>
          <b-field>
            <b-checkbox v-model="component.production" type="is-success" style="font-size: 14px">
              Production
            </b-checkbox>
          </b-field>
        </div>

        <b-field
          label="Name"
          horizontal
          custom-class="is-small "
          :type="{
            'is-danger':
              (!$v.component.name.required || !$v.component.name.minLength) && $v.component.name.$dirty,
          }"
          :message="{
            ['Field is required']:
              $v.component.name.$dirty && !$v.component.name.required,
            ['At least 5 character']:
              $v.component.name.$dirty && !$v.component.name.minLength,
          }"
        >
          <b-input
            :disabled="modalType == 'edit' ? true : false"
            type="text"
            placeholder="Name"
            size="is-small"
            class="elementName"
            v-model="$v.component.name.$model"
          ></b-input>
        </b-field>

        <b-field
          label="Description"
          horizontal
          custom-class="is-small"
          
        >
          <b-input
            placeholder="Description"
            size="is-small"
            v-model="component.description"
          ></b-input>
        </b-field>

        <b-field label="Link" horizontal custom-class="is-small">
          <b-input
            placeholder="Link"
            size="is-small"
            v-model="component.link"
          ></b-input>
        </b-field>

        <b-field label="Image" horizontal custom-class="is-small">
          <b-input
            placeholder="Image"
            size="is-small"
            v-model="component.image"
          ></b-input>
        </b-field>

        <!-- <b-field label="Image" horizontal custom-class="is-small">
          <b-field class="file">
            <b-upload
              v-model="component.image"
              expanded
              custom-class="is-small"
              size="is-small"
            >
              <a class="button is-fullwidth is-justify-content-end is-small">
                <b-icon icon="upload"></b-icon>
                <span>{{ component.image.name || "Click to upload" }}</span>
              </a>
            </b-upload>
          </b-field>
        </b-field> -->

        <b-field label="Unit price" horizontal custom-class="is-small"
        :type="{
              'is-danger': !$v.component.unit_price.required && $v.component.unit_price.$dirty,
            }"
            :message="{
              ['Field is required']: $v.component.unit_price.$dirty && !$v.component.unit_price.required,
            }"
          >
          <b-input
            type="number"
            placeholder="Unit price"
            size="is-small"
            v-model="$v.component.unit_price.$model"
          ></b-input>
        </b-field>

        <b-field label="Measure" expanded horizontal custom-class="is-small">
          <b-field
            :type="{
              'is-danger':!$v.component.formula.validFormula && $v.component.formula.$dirty,
            }"
            :message="{
              ['Not a valid formula']: $v.component.formula.$dirty && !$v.component.formula.validFormula,
            }"
          >
            <b-select
              :disabled="modalType == 'edit' ? true : false"
              required
              placeholder="Measure"
              size="is-small"
              v-model="component.unit_type"
            >
              <option value="m2">m2</option>
              <option value="m">m</option>
              <option value="none">none</option>
            </b-select>

            <b-input
              v-if="component.unit_type != 'none'"
              v-model.lazy="$v.component.formula.$model"
              type="text"
              placeholder="formula"
              expanded
              custom-class="is-small"
            ></b-input>
          </b-field>
        </b-field>

        <b-field
          label="Quantity"
          horizontal
          custom-class="is-small"
        >
          <b-input
            type="number"
            placeholder="Quantity"
            size="is-small"
            v-model="component.quantity"
          ></b-input>
        </b-field>

        <b-field label="Effort" horizontal custom-class="is-small">
          <b-input
            type="number"
            placeholder="Effort"
            size="is-small"
            v-model="component.effort"
          ></b-input>
        </b-field>

        <b-field
          label="Category"
          horizontal
          custom-class="is-small"
          :type="{
            'is-danger':
              !$v.component.category.required && $v.component.category.$dirty,
          }"
          :message="{
            ['Field is required']:
              $v.component.category.$dirty && !$v.component.category.required,
          }"
        >
          <b-select
            :disabled="modalType == 'edit' ? true : false"
            placeholder="Select a category"
            expanded
            size="is-small"
            v-model="$v.component.category.$model"
          >
            <option v-for="option in categories" :value="option" :key="option">
              {{ option }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Tags" horizontal custom-class="is-small">
          <b-taginput
            v-model="component.tags"
            :data="this.filteredTags"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="component.tags"
            icon="label"
            placeholder="Add tags"
            @typing="getFilteredTags"
            attached
            size="is-small"
            :append-to-body="true"
          >
          </b-taginput>
        </b-field>

        <b-field
          label="RAL Code"
          horizontal
          custom-class="is-small"
          v-if="component.paint" 
        >
          <b-input placeholder="RAL Code" size="is-small" v-model="component.ral_code"></b-input>
        </b-field>

        <b-field
          label="Phases"
          horizontal
          custom-class="is-small"
          v-if="component.production"
        >
          <b-taginput
            v-model="component.phases"
            :data="this.filteredPhases"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="component.phases"
            icon="label"
            placeholder="Add phases"
            @typing="getFilteredPhases"
            attached
            size="is-small"
            :append-to-body="true"
          >
          </b-taginput>
        </b-field>

        <b-field label="Comments" class="is-hidden">
            <b-input type="textarea"
             v-model="component.comments"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button @click="save" class="button is-info is-small">
          Save changes
        </button>
        <button @click="close" class="button is-small" data-bulma-modal="close">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
const validFormula = function (value) {
  if(this.component.unit_type == 'none') {
    return true
  }
  try {
    let formula = eval(
      value
        .replaceAll("l", this.house.length)
        .replaceAll("w", this.house.width)
        .replaceAll("h", this.house.height)
        .replaceAll("%", "*0.01")
    );
    return true;
  } catch (error) {
    return false;
  }
};
export default {
  name: "AddNewElementModal",
  props: ["projectName", "modalType"],
  validations: {
    component: {
      name: {
        required,
        minLength: minLength(5),
      },
      // description: {
      //   required,
      //   minLength: minLength(5),
      // },
      // quantity: {
      //   required,
      // },
      unit_price: {
        required,
      },
      category: {
        required,
      },
      formula: {
        validFormula,
      },
    },
  },
  data() {
    return {
      newElementName: "",
      component: {
        name: null,
        description: null,
        image: null,
        link: null,
        quantity: null,
        unit_type: null,
        unit_data: null,
        unit_price: null,
        category: null,
        tags: [],
        phases: [],
        ral_code: null,
        client: false,
        production: false,
        acquisition: false,
        paint: false,
        total_price: null,
        formula: null,
        comments: null
      },
      image: {},
      paintChecked: false,
      filteredTags: this.tagsData,
      filteredPhases: this.phasesData,
    };
  },
  mounted() {
    this.duplicateObject();
  },
  computed: {
    showUnitData: {
      get() {
        let measures = ["none"];
        if (measures.includes(this.component.unit_type)) {
          return false;
        } else {
          return true;
        }
      },
    },
    showUnitDataFormula: {
      get() {
        let measures = ["none", "m2", "m"];
        if (measures.includes(this.component.unit_type)) {
          return false;
        } else {
          return true;
        }
      },
    },
    categories: {
      get() {
        return this.$store.state.categories;
      },
    },
    tagsData: {
      get() {
        return this.$store.state.tags;
      },
    },
    phasesData: {
      get() {
        return this.$store.state.phases;
      },
    },
    selectedModel: {
      get() {
        return this.$store.state.selectedModelInstance;
      },
    },
    house: {
      get() {
        return this.$store.state.house;
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.component.total_price = null;
        if(this.component.quantity == null) {
          this.component.quantity = 1
        }
        if (this.modalType == "edit") {
          this.$store
            .dispatch("updateSelectedInstance", this.component)
            .then((response) => {
              this.close();
            });
        } else if (this.modalType == "duplicate" || this.modalType == "new") {
          this.$store
            .dispatch("addComponent", this.component)
            .then((response) => {
              console.log("component.duplicated");
              this.close();
            });
        }
      }    
    },
    duplicateObject() {
      if (this.selectedModel) {
        this.component.id = this.selectedModel.id;
        this.component.name = this.selectedModel.name;
        this.component.description = this.selectedModel.description;
        this.component.image = this.selectedModel.image;
        this.component.link = this.selectedModel.link;
        this.component.quantity = this.selectedModel.quantity;
        this.component.unit_price = this.selectedModel.unit_price;
        this.component.unit_type = this.selectedModel.unit_type;
        this.component.unit_data = this.selectedModel.unit_data;
        this.component.effort = this.selectedModel.effort;
        this.component.category = this.selectedModel.category;
        this.component.ral_code = this.selectedModel.ral_code;
        this.component.tags = this.selectedModel.tags;
        this.component.phases = this.selectedModel.phases;
        this.component.client = this.selectedModel.client;
        this.component.paint = this.selectedModel.paint;
        this.component.acquisition = this.selectedModel.acquisition;
        this.component.production = this.selectedModel.production;
        this.component.formula = this.selectedModel.formula;
        this.component.comments = this.selectedModel.comments;
      }
    },
    getFilteredTags(text) {
      this.filteredTags = this.tagsData.filter((option) => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    getFilteredPhases(text) {
      this.filteredPhases = this.phasesData.filter((option) => {
        return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    evalIsRight(text) {
      let globalMeasure = eval(
        text.formula
          .replaceAll("l", this.house.length)
          .replaceAll("w", this.house.width)
          .replaceAll("h", this.house.height)
          .replaceAll("%", "*0.01")
      );

      if (globalMeasure) {
        console.log("globalMeasure", true);
        return true;
      } else {
        console.log("globalMeasure", false);
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.field.file {
  span {
    color: rgba(122, 122, 122, 0.7);
  }
}

.modal-card {
  min-height: 710px;
}
.modal.has-overflow {
  position: fixed !important;
  overflow: auto !important;
  .modal-background {
    position: fixed !important;
  }
  .modal-content {
    overflow: visible !important;
  }
  .modal-card {
    overflow: visible !important;
  }
  .modal-card-body {
    overflow: visible !important;
  }
}
</style>